<!--
 * @Description:  图形化看板
 * @Author: zhang zhen
 * @Date: 2023-04-27 11:19:12
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-01-12 15:36:32
 * @FilePath: /page-sass/src/views/workSpace/graphicalPlane.vue
-->
<template>
  <div class="graphicalPlane">
    <!-- tradeIdentity：0-供应商，2-采购商 -->
    <!-- v-if="setUserInfo().tradeIdentity == 0" -->
    <Supplier v-if="setUserInfo().tradeIdentity == 0" />
    <Buyers v-else />
  </div>
</template>

<script>
import Supplier from './graphicalModules/Supplier.vue';
import Buyers from './graphicalModules/Buyers.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'graphicalPlane',
  components: {
    Buyers,
    Supplier
  },
  data() {
    return {
      
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
  },
}
</script>

<style lang="less" scoped>
.graphicalPlane {
  
}
</style>
